import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, filter, tap, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { RouterNavigationAction, routerNavigationAction } from '@ngrx/router-store';

import { CommonAuthActions, CustomRouterState, FromCommonAuth } from 'coop-web-common';
import { Router } from '@angular/router';
import { klubLoginUser } from 'projects/coopklub-web/src/app/ngrx/klub-app.actions';
import { moduleRoutes } from 'projects/coopklub-web/src/app/shared/models/app-coopklub-routes.model';


@Injectable()
export class KlubAppEffects {

  // try to login on navigated from registration completed
  navigationTryLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigationAction<CustomRouterState>>(routerNavigationAction),
      filter((action) => action.payload.routerState.queryParams.from === 'reg'),
      concatMap(action => of(action).pipe(
        withLatestFrom(
          this.store$.pipe(select(FromCommonAuth.selectLoggedIn)),
        ),
      )),
      filter(([action, loggedIn]) => !loggedIn),
      map(() => klubLoginUser())
    )
  );

  klubLoginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(klubLoginUser),
      map(() => CommonAuthActions.commonAuthLoginUserWithSuccessReturnUrl({
        loginSuccessReturnUrl: window.location.origin + `/${moduleRoutes.klubUser}`,
      }))
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private router: Router,
  ) { }

}
