import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'coop-web-common';
import { KlubNotFoundPageComponent } from 'projects/coopklub-web/src/app/klub-common/common/klub-not-found-page/klub-not-found-page.component';
import { moduleRoutes } from 'projects/coopklub-web/src/app/shared/models/app-coopklub-routes.model';
import { environment } from 'projects/coopklub-web/src/environments/environment';

const routes: Routes = [
  {
    path: moduleRoutes.klubUser,
    loadChildren: () => import('./klub-user/klub-user.module').then(m => m.KlubUserModule),
    canActivate: [AuthGuard],
  },

  {
    path: moduleRoutes.klubRegistration,
    loadChildren: () => import('./klub-registration/klub-registration.module').then(m => m.KlubRegistrationModule),
  },

  // articles
  //
  {
    path: moduleRoutes.klubArticleBenefits,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleDiscountPayments,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleMobileApplication,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleTermsAndConditions,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleGdpr,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleGdprWithdrawal,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleSpecialOffer,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleContact,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleCampaign,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  {
    path: moduleRoutes.klubArticleEReceipt,
    loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
  },
  ...(environment.showDevOptions
    ? [{
      path: moduleRoutes.klubArticleTest,
      loadChildren: () => import('./klub-article/klub-article.module').then(m => m.KlubArticleModule),
    }]
    : []
  ),

  {
    path: moduleRoutes.klubPartners,
    loadChildren: () => import('./klub-partners/klub-partners.module').then(m => m.KlubPartnersModule),
  },
  {
    path: moduleRoutes.klubFaq,
    loadChildren: () => import('./klub-faq/klub-faq.module').then(m => m.KlubFaqModule),
  },

  {
    path: '',
    loadChildren: () => import('./klub-home/klub-home.module').then(m => m.KlubHomeModule),
    pathMatch: 'full',
  },

  { path: '**', component: KlubNotFoundPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
