import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { fromRouter } from 'coop-web-common';

export interface State {
  [fromRouter.routerFeatureKey]: fromRouter.State;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.reducer,
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
