import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, inject, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Angulartics2Module } from 'angulartics2';
import {
  CoopKlubApiModule,
  CoopKlubConfiguration,
  CoopWebCommonAuthModule,
  CoopWebCommonComponentsModule,
  CoreApiModule,
  CoreConfiguration,
  CustomRouterState,
  CustomRouterStateSerializer,
  SnackBarService,
} from 'coop-web-common';
import { environment } from 'projects/coopklub-web/src/environments/environment';
import { reducers, metaReducers } from './reducers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KlubCommonModule } from './klub-common/klub-common.module';
import { KlubAppEffects } from 'projects/coopklub-web/src/app/ngrx/klub-app.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function coreConfig() {
  return new CoreConfiguration({ basePath: environment.coreApiUrl });
}
export function coopKlubConfig() {
  return new CoopKlubConfiguration({ basePath: environment.coopKlubApiUrl });
}

@Injectable()
export class KlubErrorHandler implements ErrorHandler {
  constructor(private snackBarService: SnackBarService) { }

  handleError(error) {
    // DEBUG
    console.log(error);
    // this.snackBarService.showError(`GlobalErrorHandler: ${error?.toString().substring(0, 500)}`);
  }
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CoopWebCommonComponentsModule,
    CoreApiModule.forRoot(coreConfig),
    CoopKlubApiModule.forRoot(coopKlubConfig),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      name: 'Coopkonto',
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot<CustomRouterState>({
      serializer: CustomRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    EffectsModule.forFeature([KlubAppEffects]),
    CoopWebCommonAuthModule.forRoot(environment.commonAuthConfig),
    KlubCommonModule,
    Angulartics2Module.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: KlubErrorHandler },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
