import { Component, Inject, LOCALE_ID } from '@angular/core';
import moment from 'moment';
import 'moment/locale/sk'; // bug workaround: https://github.com/angular/components/issues/28263
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { environment } from 'projects/coopklub-web/src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'coopklub-web';
  env = environment.env;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    angulartics: Angulartics2GoogleGlobalSiteTag
  ) {
    moment.locale(locale);
    console.log(`v${environment.appVersion}, ${environment.env}`);
    angulartics.startTracking();
  }
}
